
import Vue from "vue";
import TheFooter from "@/components/TheFooter.vue";

export default Vue.extend({
  components: {
    TheFooter
  },
  computed: {
    roles(): string[] {
      return this.$store.state.user.me.role;
    },
    userHasToSignUp(): boolean {
      return this.roles.includes("nearestGroupStudent")
            || this.roles.includes("potentialStudent")
            || this.roles.includes("readyToContinue");
    }
  },
  data() {
    return {
      errorWall: ["signup", "signin"]
    };
  },
  async created() {
    await this.$store.dispatch("user/getRole");
    if (
      this.errorWall.includes(`${this.$route.name}`)
      && !this.userHasToSignUp
    ) {
      await this.$router.push("/");
    }
    if (
      this.userHasToSignUp
      && this.$route.name !== "signup"
      && !this.roles.includes("volunteer")
    ) {
        await this.$router.push("/signup");
    }
  }
});
