import RequestInstance from "@/api/request";
import prodConfig from "@/config";
import {ApiConfig} from "@/types.d";
import { AxiosRequestConfig } from "axios";

const conf: ApiConfig = prodConfig; // (process.env.NODE_ENV === "production" ? prodConfig : devConfig);
const http = RequestInstance(conf);

const api = {
  async get(what: string, params = {}) {
    const url: string = conf.endpoints[what];
    return http.get(url, {params});
  },
  async post(what: string, data: any = {}, config?: AxiosRequestConfig) {
    const url: string = conf.endpoints[what];
    return http.post(url, data, config);
  }
};

export default api;
export {conf};
