
import Vue from "vue";

export default Vue.extend({
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
});
