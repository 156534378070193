import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue")
    },
    {
      path: "/user_not_found",
      name: "userNotFound",
      component: () => import(/* webpackChunkName: "userNotFound" */ "@/views/UserNotFound.vue")
    },
    {
      path: "/material/:level/:filename",
      name: "material",
      component: () => import(/* webpackChunkName: "material" */ "@/views/Material.vue")
    },
    {
      path: "/material",
      name: "material-old",
      component: () => import(/* webpackChunkName: "material" */ "@/views/Material.vue")
    },
    {
      path: "/signin",
      name: "signin",
      component: () => import(/* webpackChunkName: "signin" */ "@/views/NonAuthorized.vue")
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import(/* webpackChunkName: "signup" */ "@/views/NearestStartRegistration.vue")
    },
    {
      path: "*",
      name: "404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/NotFound.vue")
    }
  ]
});

export default router;
