import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import user from "./modules/user";
import levels from "./modules/levels";
import review from "./modules/review";
import modals from "./modules/modals";
import materials from "./modules/materials";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    config,
    user,
    review,
    levels,
    modals,
    materials
  },
  plugins: process.env.NODE_ENV !== "production"
    ? [createLogger({
      collapsed: true
    })]
    : []
});

export default store;
