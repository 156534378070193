import {SystemConfig} from "@/types.d";
import api from "@/api";
import Vue from "vue";

const state: SystemConfig = {
  _filled: false,
  daysToDoHomework: 0,
  reviewsDuration: [],
  reviewRegistrationPeriod: {
    startDay: 0,
    finishDay: 0
  },
  levelDuration: 0,
  meetUps: [],
  lang: "",
  online: false,
  showFeedbackMark: {
    examinee: false,
    reviewer: false
  },
  feedbackReceivers: {
    examinee: false,
    reviewer: false
  },
  obligatedMeetings: [],
  tasksPerLevel: []
};

const mutations = {
  update(state: any, data: any) {
    Object.keys(data).forEach((key: string) => {
      Vue.set(state, key, data[key]);
    });
  }
};

const actions = {
  async get({commit}: any) {
    const {data} = await api.get("config");
    commit("update", {
      ...data,
      _filled: true
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
