import api from "@/api";
import {ActiveLevel, Level, LevelsData, ReviewParticipant, SystemConfig} from "@/types.d";
import Vue from "vue";
import {fillAdditionalLevelFields} from "@/utils";

const state: LevelsData = {
  all: []
};

const getters = {
  getLevel: (state: any) => (level: number): Level => {
    return state.all.find((l: Level): boolean => l.level === level);
  }
};


const mutations = {
  update(state: any, {levels, config}: {levels: Level[], config: SystemConfig}) {
    state.all = levels.map((l: Level) => fillAdditionalLevelFields(l, config));
  },
  updateLevel(state: any, data: ActiveLevel) {
    const index: number = state.all.findIndex((l: Level): boolean => l.level === data.level);
    Vue.set(state.all, index, data);
  }
};

const actions = {
  async get({commit, dispatch, rootState}: any) {
    const {data} = await api.get("levels");
    commit("update", {
      levels: data.levels,
      config: rootState.config
    });
    commit("user/update", {users: data.usersContacts}, {root: true});
    dispatch("user/setMeetUps", null, {root: true});
  },
  async getExtendedLevel({commit, rootState}: any, levelNumber: any) {
    const {data} = await api.get("detailed_level", {levelNumber});
    commit("updateLevel", fillAdditionalLevelFields(data, rootState.config));
  },
  async getVideoLink({commit}: any, params: any) {
    const {data} = await api.get("video_link", params);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
