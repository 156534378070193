import api from "@/api";
import {Materials} from "@/types";

const state: {
  all: Materials
} = {
  all: {
    materials: [],
    other: {}
  }
};

const mutations = {
  update(state: {all: Materials}, data: Materials) {
    state.all = data;
  }
};

const actions = {
  async getFile(_: any, params: any) {
    return api.get("material", params);
  },
  async getVideo(_: any, params: any) {
    return api.get("video", params);
  },
  async get({commit}: any) {
    const {data} = await api.get("materials");
    commit("update", data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
