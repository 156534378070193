import Vue from "vue";
import "@/plugins/element";
import "@/plugins/luxon";
import "@/assets/styles/app.scss";
import i18n from "@/plugins/i18n-multilang";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import VueProgressBar from "vue-progressbar";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VueCookies from "vue-cookies";
import VueHead from "vue-head";


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:  process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  });
}
Vue.use(VueHead);
Vue.use(VueProgressBar, {});
Vue.use(VueCookies);

Vue.config.productionTip = false;

export default new Vue({
  render: (h) => h(App),
  i18n,
  router,
  store
}).$mount("#app");
