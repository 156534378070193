import api from "@/api";
import {ActiveLevel, Lecture, Level, Material, MeetUp, UsersData} from "@/types.d";
import Vue from "vue";

const defaultState: UsersData = {
  me: {
    id: 0,
    firstName: "",
    lastName: "",
    discord: "",
    phone: "",
    city: "",
    warnings: {
      received: 0,
      max: 0
    },
    averageMark: 0,
    vacation: {
      total: 0,
      available: 0
    },
    startStudyDateTS: 0,
    frozen: false,
    avatar: "",
    level: 0,
    role: [],
    dropped: false
  },
  reviewRegistration: {
    current: {
      availability: "",
    },
    future: {
      availability: ""
    }
  },
  weekPlan: [],
  now: {
    meetUps: [], // в онлайне не будет
    vacation: {
      startTS: 0,
      endTS: 0,
      status: "available"
    },
    todayTS: 0,
    lastFeedbackTS: 0 // для онлайн не нужно
  },
  users: {},
  materials: [],
  helpRequested: false
};

const mutations = {
  update(state: UsersData, {user, now, users, weekPlan, role, registration, dropped}: any) {
    role = role || state.me.role;
    dropped = dropped || state.me.dropped;
    state.me = user || state.me;
    state.now = now || state.now;
    state.weekPlan = weekPlan || state.weekPlan;
    state.users = users || state.users;
    state.reviewRegistration = registration || state.reviewRegistration;
    Vue.set(state.me, "role", role);
    Vue.set(state.me, "dropped", dropped);
  },
  materials(state: UsersData, materials: Material[][]) {
    state.materials = materials;
  },
  helpRequested(state: UsersData, newstate: boolean) {
    state.helpRequested = newstate;
  }
};

const actions = {
  async get({commit, rootState}: any, volunteer: boolean = false) {
    const { data } = await api.get(volunteer ? "volunteer" : "user");
    if (volunteer) {
      commit("levels/update", {
        levels: data.levels.map((l: Level): Level => ({
          ...l,
          closed: false
        })),
        config: rootState.config
      }, {root: true});
    }
    commit("update", {
      user: data.user,
      now: data.now,
      users: data.usersContacts,
      weekPlan: data.weekPlan,
      registration: data.reviewRegistration
    });
  },
  async getIntro() {
    return api.get("intro");
  },
  async getMaterials({commit}: any) {
    const {data: {materials}} = await api.get("all_materials");
    commit("materials", materials);
  },
  async toggleNearestStart(_: any, {action, startDate}: {action: string, startDate: string}) {
    return api.post(action, {startDate});
  },
  async getRole({commit}: any) {
    const {data: {userRole, dropped}} = await api.get("status");
    commit("update", {role: userRole, dropped});
  },
  async toggleVacation({dispatch}: any, params: any) {
    await api.post("toggle_vacation", params);
    await Promise.all([
      dispatch("get"),
      dispatch("levels/get", null, {root: true})
    ]);
  },
  setMeetUps({state, rootState, commit}: any) {
    // TODO: add to meetups array the fist meeting depending on system config
    // getting all today meetups of current active levels
    const meetUps: MeetUp[] = rootState.levels.all
    // only active levels
      .filter((l: Level): boolean => !l.closed)
      .reduce((acc: number[], l: ActiveLevel): number[] => {
        const ms: number[] = rootState.config.meetUps
          .filter((m: number): boolean => m === l.day);
        // TODO: i leave this code for the future, when the meetups array will be more complex like this
        // "meetUps": [
        //       {
        //         "days": [2],
        //         "levels": [1],
        //         "type": "first",
        //         "required": true
        //       },
        // // only meetups that are appropriate for this level
        //   .filter((m: MeetUp): boolean => !m.levels || m.levels.includes(l.level))
        //
        //   // only the ones i have today
        //   .filter((m: MeetUp): boolean => m.days.includes(l.day))
        //   .map((m: MeetUp): MeetUp => ({
        //     ...m,
        //     currentLevels: [l.level]
        //   }));
        // to flatten array
        return [...acc, ...ms];
      }, []);

    commit("update", {
      now: {
        ...state.now,
        meetUps
      }
    });
  },
  async sendFeedback({dispatch}: any, params: any) {
    await api.post("send_feedback", params);
    await dispatch("get");
  },
  async requestDiscordHelp({commit}: any, params: Record<string, string>) {
    commit("helpRequested", true);
    try {
      await api.post("ask_for_help", {}, {params});
    } catch (e) {
      commit("helpRequested", false);
    }

  }
};

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations
};
