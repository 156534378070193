import axios from "axios";
import {ApiConfig} from "@/types.d";
import app from "../main";
import {links} from "@/config";

declare module "axios" {
  interface AxiosRequestConfig {
    successMessage?: string;
  }
}

const Instance = (config: ApiConfig) => {
  const instance = axios.create({
    baseURL: config.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });

  instance.interceptors.request.use((config) => {
    app.$Progress.start(); // for every request start the progress
    config.successMessage = config.params?.successMessage;
    return config;
  });

  instance.interceptors.response.use((response) => {
    app.$Progress.finish(); // finish when a response is received
    const errorNames: string[] = ["404", "signin"];
    const routeName: string = app.$route.name || "";

    if (errorNames.includes(routeName)) {
      setTimeout(() => app.$router.push("/"), 2500);
    }

    if (response.config.method === "post") {
      app.$message({
        type: "success",
        message: response.config.successMessage || `${app.$t("saved")}`
      });
    }
    return response.data;
  }, async (error) => {
    app.$Progress.fail();
    if (error.response.data && error.response.data.error) {
      switch (error.response.data.error.code) {
        case 400: // not authorized
          app.$message({
            type: "error",
            message: error.response.data.error.message
          });
          return Promise.reject(error.response.data);
        case 401: // not authorized
          await app.$router.push("/signin");
          setTimeout(() => window.location.href = links.SIGN_IN, 2500);
          break;
        case 418: // studying hadn't start yet
          await app.$router.push("/signup");
          break;
        case 404:
          if (error.response.data.error.message === "User not found") {
           await app.$router.push("/user_not_found");
          }
          break;
        default:
          app.$message({
            type: "error",
            message: `${app.$t("error.unknown", {e: JSON.stringify(error.response.data.error)})}`
          });
      }
      return Promise.reject(error.response.data);
    }
    app.$message({
      type: "error",
      message: `${app.$t("error.unknown", {e: JSON.stringify(error.response.data)})}`
    });
    return Promise.reject(error);
  });
  return instance;
};

export default Instance;
