import Vue from "vue";

const state: {
  [index: string]: {
    visible: boolean;
    data?: any
  }
} = {
  evaluation: {
    visible: false,
    data: null
  },
  review: {
    visible: false,
    data: null
  },
  videos: {
    visible: false,
    data: null
  },
  video: {
    visible: false,
    data: null
  },
  homeworkUpload: {
    visible: false,
    data: null
  }
};

const mutations = {
  update(state: any, {data, visible, type}: {data: any, visible: boolean, type: string}) {
    Vue.set(state[type], "visible", visible);
    Vue.set(state[type], "data", data);
  }
};

const actions = {
  set({commit}: any, data: any) {
    commit("update", data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
